import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from '../../core/services/event.service';
import { storageService } from '../../service/storage.service-new';
import { CommonServiceService } from '../../service/common-service.service';
declare var $: any;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  public name: any;
  public pageHeading: string;
  public openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  @Input('topbarName') set topbarName(value) {
    //console.log(value);
    this.name = value;
  }

  constructor(
    public commonService: CommonServiceService) { }

  ngOnInit() {
    var routeLink = this.commonService.router.url;
    this.commonService.autoLogout(routeLink);
    this.openMobileMenu = false;

    $("#openit").click(function () {
      $("body").toggleClass("enlarged");
    });

    this.commonService.storageService.getItem('fullName').then((dbres) => {
      if (dbres) {
        this.commonService.user = {
          name: dbres,
          id: null
        }
        this.commonService.storageService.getItem('id').then((dbres1) => {
          if (dbres1) {
            this.commonService.user.id = dbres1
          }
        });
      } else {
        this.commonService.user = undefined;
      }
    });
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    // this.commonService.storageService.deleteItem('token');
    // this.commonService.storageService.deleteItem('fullName');
    // this.commonService.storageService.deleteItem('emailID');
    // this.commonService.storageService.deleteItem('nextpre');
    this.commonService.storageService.clear().finally(() => {
      this.commonService.router.navigate(['']);
    })
  }

}
