import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';



const routes: Routes = [
  { path: '', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'pages', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
  { path: 'misc', loadChildren: () => import('./misc/misc.module').then(m => m.MiscModule) },
  { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
  { path: 'configuration', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule) },
  { path: 'reviews', loadChildren: () => import('./reviews/reviews.module').then(r => r.ReviewsModule) },
  { path: 'search-history', loadChildren: () => import('./search-history/search-history.module').then(s => s.SearchHistoryModule) },
  { path: 'brands', loadChildren: () => import('./brands/brands.module').then(m => m.BrandsModule) },
  { path: 'categories', loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule) },
  { path: 'sub-categories', loadChildren: () => import('./sub-categories/sub-categories.module').then(m => m.SubCategoriesModule) },
  { path: 'pending-orders', loadChildren: () => import('./pending-orders/pending-orders.module').then(m => m.PendingOrdersModule) },
  { path: 'shipped-orders', loadChildren: () => import('./shipped-orders/shipped-orders.module').then(m => m.ShippedOrdersModule) },
  { path: 'completed-orders', loadChildren: () => import('./completed-orders/completed-orders.module').then(m => m.CompletedOrdersModule) },
  { path: 'coupan-code', loadChildren: () => import('./coupan-code/coupan-code.module').then(m => m.CoupanCodeModule) },
  { path: 'blogs', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  { path: 'prime-pricing', loadChildren: () => import('./sos-prime-pricing/sos-prime-pricing.module').then(spp => spp.SosPrimePricingModule) },
  { path: 'prime-orders', loadChildren: () => import('./sos-prime-order/sos-prime-order.module').then(spo => spo.SosPrimeOrderModule) },
  { path: 'prime-coupon-code', loadChildren: () => import('./sos-prime-coupon-code/sos-prime-coupon-code.module').then(spcc => spcc.SosPrimeCouponCodeModule) },
  { path: 'seo', loadChildren: () => import('./SEO/auth/auth.module').then(m => m.AuthModule) },
  { path: 'seo-product', loadChildren: () => import('./SEO/product/product.module').then(m => m.ProductModule) },
  { path: 'seo-brand', loadChildren: () => import('./SEO/brand/brand.module').then(m => m.BrandModule) },
  { path: 'seo-category', loadChildren: () => import('./SEO/categories/categories.module').then(m => m.CategoriesModule) },
  { path: 'seo-subcategory', loadChildren: () => import('./SEO/subcategory/subcategory.module').then(m => m.SubcategoryModule) },
  { path: 'seo-slider', loadChildren: () => import('./SEO/slider/slider.module').then(m => m.SliderModule) },
  { path: 'seo-banner', loadChildren: () => import('./SEO/banners/banners.module').then(m => m.BannersModule) },
  { path: 'seo-blogs', loadChildren: () => import('./SEO/blog/blog.module').then(m => m.BlogModule) },
  { path: 'seo-faq', loadChildren: () => import('./SEO/faq/faq.module').then(f => f.FaqModule) },
  { path: 'seo-review', loadChildren: () => import('./SEO/seo-review/seo-review.module').then(sr => sr.SeoReviewModule) },
  { path: 'link-and-redirection', loadChildren: () => import('./links-and-redirection/links-and-redirection.module').then(lar => lar.LinksAndRedirectionModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }