import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { storageService } from '../../service/storage.service-new';
import { CommonServiceService } from '../../service/common-service.service';
declare var $: any;

@Component({
  selector: 'app-seo-top-bar',
  templateUrl: './seo-top-bar.component.html',
  styleUrls: ['./seo-top-bar.component.scss']
})
export class SeoTopBarComponent implements OnInit {

  public pageHeading: string;
  public notificationItems: Array<{}>;
  public openMobileMenu: boolean;
  public name: any;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  @Input('topbarName') set topbarName(value) {
    this.name = value;
  }

  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    private storageService: storageService) {
    this.commonService.headerUpdate$.subscribe(isUpdate => {
      if (isUpdate) {
        this.initCall();
      }
    });
  }

  ngOnInit() {
    /* var routeLink = this.router.url;
    this.commonService.autoLogout(routeLink); */
    this.openMobileMenu = false;
    $("#openit").click(function () {
      $("body").toggleClass("enlarged");
    });
    this.initCall();
  }

  initCall() {
    this.storageService.getItem('seoUserDetail').then((dbres) => {
      if (dbres) {
        this.commonService.user = {
          userObj: dbres
        }
      } else {
        this.commonService.user = undefined;
      }
    });
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  logout() {
    this.storageService.deleteItem('seoToken');
    this.storageService.deleteItem('seoUserDetail');
    this.router.navigate(['/seo/login']);
  }

}
