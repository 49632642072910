import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from './service/common-service.service';
import { storageService } from './service/storage.service-new';

@Component({
  selector: 'app-adminto',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public name: any;
  public title: any = 'SOS Admin'
  constructor(public storageService: storageService, public api: CommonServiceService) { }

  handler(event) {
    this.name = event;
  }

  ngOnInit() {
    this.storageService.addItem('sos', this.title);
    this.api.gettoken();
  }

}
