import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from '../shared/ui/ui.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';

import { RouterModule, Routes } from '@angular/router';
import { SeoTopBarComponent } from './seo-top-bar/seo-top-bar.component';
import { SeoSideBarComponent } from './seo-side-bar/seo-side-bar.component'



@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    SeoTopBarComponent,
    SeoSideBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    ClickOutsideModule,
    UIModule,
  ],
  exports: [
    SidebarComponent,
    TopbarComponent,
    SeoTopBarComponent,
    SeoSideBarComponent
  ]
})
export class LayoutsModule { }
